.ProductList {
}

.ProductCard {
    /* background-color: #f5f5f5; */
    height: 100%;
    min-height: 400px;
    min-width: 252px;
    /* border: solid rgb(0, 107, 168) 0.75px; */
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px 1.5px; */
    border-radius: 4px;
    padding-bottom: 0.25rem;
}

.ProductCard:hover {
    /* box-shadow: rgba(3, 102, 214, 0.75) 0px 0px 0px 1.5px; */
    transition: transform 0.3s ease;
}

.card-upper {
    height: 35%;
}

.card-lower {
    height: 50%;
    margin-top: 0.75rem;
    max-width: 92%;
    text-align: left;
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: column;
    padding: 0 0.9rem;
}

/*--------------- Card Text ---------------*/
.card-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
}

.card-name {
    font-size: 1.05rem !important;
    color: #666 !important;
    line-height: 1.1rem !important;
    font-weight: 500;

    height: 2.25rem;

    overflow: hidden !important;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    word-wrap:break-word;
    -webkit-box-orient: vertical;
}

.card-price {
    /* height: 30%; */
}

.card-moq {
    /* height: 20%; */
    margin-top: auto;
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
}

.card-name:hover {
    text-decoration: underline;
    cursor: pointer;
}
/*--------------- Card Text ---------------*/

/* .img-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    background-blend-mode: overlay;
    border-radius: 4px;
    cursor: pointer;
    justify-content: center;
    overflow: hidden;
    position: relative;
    max-height: 250px;
    min-width: 250px;
    border: solid rgba(47, 180, 255) 0.75px;
} */

/* .img-container::after {
    background: rgba(0, 0, 0, 0.03) !important;
    bottom: 0 !important;
    content: "" !important;
    left: 0 !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    z-index: 1 !important;
} */

.img-container:hover {
    .card-img {
        transform: scale(1.05);
        transition: transform 0.3s ease;
    }
}
