/* Home Page */

header.masthead {
    padding-top: 10.5rem;
    /* padding-bottom: 6rem; */
    text-align: center;
}
header.masthead .masthead-subheading {
    font-size: 1.5rem;
    font-style: italic;
    line-height: 1.5rem;
    margin-bottom: 10px;
    font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
header.masthead .masthead-heading {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.25rem;
    margin-bottom: 2rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (min-width: 768px) {
    header.masthead {
        padding-top: 17rem;
        padding-bottom: 3.5rem;
    }
    header.masthead .masthead-subheading {
        font-size: 2.25rem;
        font-style: italic;
        line-height: 2.25rem;
        margin-bottom: 2rem;
    }
    header.masthead .masthead-heading {
        font-size: 4.5rem;
        font-weight: 700;
        line-height: 4.5rem;
        margin-bottom: 4rem;
    }
}

.home-title {
    font-size: 2.5rem !important;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.category-image {
    /* max-height: 100% !important; */
    border-radius: 0.5rem;
}

#category .category-item {
    /* height: 24rem; */
    max-width: 26rem;
    margin-left: auto;
    margin-right: auto;
}

#category .category-item .category-link {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 70%;
}
#category .category-item .category-link .category-hover {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(48, 131, 255, 0.4);
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity ease-in-out 0.25s;
}
#category .category-item .category-link .category-hover .category-hover-content {
    font-size: 1.25rem;
    color: white;
}
#category .category-item .category-link:hover .category-hover {
    opacity: 1;
}
#category .category-item .category-caption {
    padding: 0.5rem 1.5rem;
    text-align: center;
    background-color: #fff;
    height: 30%;
}
#category .category-item .category-caption .category-caption-heading {
    font-size: 2rem;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    margin-bottom: 0;
}
#category .category-item .category-caption .category-caption-subheading {
    font-style: italic;
    font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.page-section {
    padding-bottom: 6rem;
}

section#contact {
    background-color: #212529;
    background-image: url("https://i.imgur.com/DWZ8YMm.png");
    background-repeat: no-repeat;
    background-position: center;
}
section#contact .section-heading {
    color: #fff;
}
section#contact form#contactForm .form-group {
    margin-bottom: 1.5rem;
}
section#contact form#contactForm .form-group input,
section#contact form#contactForm .form-group textarea {
    padding: 1.25rem;
}
section#contact form#contactForm .form-group input.form-control {
    height: auto;
}
section#contact form#contactForm .form-group-textarea {
    height: 100%;
}
section#contact form#contactForm .form-group-textarea textarea {
    height: 100%;
    min-height: 10rem;
}
section#contact form#contactForm p.help-block {
    margin: 0;
}
section#contact form#contactForm .form-control:focus {
    border-color: #ffc800;
    box-shadow: none;
}
section#contact form#contactForm ::-webkit-input-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}
section#contact form#contactForm :-moz-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}
section#contact form#contactForm ::-moz-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}
section#contact form#contactForm :-ms-input-placeholder {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #ced4da;
}

.carousel-card-body {
    position: relative;
}

.carousel-card-body:before {
    position: absolute;
    content: "";
    width: 60%;
    left: 10%;
    top: 30%;
    right: 0;
    border-top: 2px solid red;
    border-color: red;

    -webkit-transform:rotate(-10deg);
    -moz-transform:rotate(-10deg);
    -ms-transform:rotate(-10deg);
    -o-transform:rotate(-10deg);
    transform:rotate(-10deg);
  }
